import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m23.2 13.72-3.6-2.472L16 13.72a.8.8 0 0 0 .88 1.32l1.976-1.36a6.84 6.84 0 0 1-6.056 7.536V8h4a.8.8 0 0 0 0-1.6h-3.552a2.824 2.824 0 1 0-2.496 0H7.2a.8.8 0 1 0 0 1.6h4v13.216A6.88 6.88 0 0 1 5.128 14.4a6.617 6.617 0 0 1 0-.688L7.12 15.04a.8.8 0 0 0 .448.136A.8.8 0 0 0 8 13.72l-3.584-2.472L.8 13.72a.804.804 0 1 0 .92 1.32l1.84-1.264v.608a8.488 8.488 0 1 0 16.968 0V13.8l1.808 1.24a.8.8 0 0 0 .448.136.8.8 0 0 0 .416-1.456ZM12 2.648a1.232 1.232 0 1 1 .016 2.464A1.232 1.232 0 0 1 12 2.648Z"
      fill="#022118"
    />
  </svg>
)

const AnchorIcon = memo(SvgComponent)
export default AnchorIcon
